var images_v = document.getElementsByClassName('foto');
var images_mp4 = document.getElementsByClassName('foto_mp4');
var videos_mp4 = document.getElementsByClassName('video-grande-mp4');
var videos_vimeo = document.getElementsByClassName('video-vimeo-post');

var iframes = document.getElementsByTagName("iframe");

var plays = document.getElementsByClassName('play-btn');
var pauses = document.getElementsByClassName('pause-btn');
var video_post = document.getElementsByClassName('video-post');


function playVimeo(video_p, play, pause, player, foto) {
    var video_active = document.getElementById(video_p);
    var vimeo = new Vimeo.Player(document.getElementById(player));
    var video = document.getElementById(player);
    var img = document.getElementById(foto);
    var play_btn = document.getElementById(play);
    var pause_btn = document.getElementById(pause);
    // loop through all '.foto' elements
    for (var i = 0; i < images_v.length; i++) {
        images_v[i].classList.remove('play');
    }
    // loop through all '.play-btn' elements
    for (var i = 0; i < plays.length; i++) {
        plays[i].classList.remove('active');
    }
    // loop through all '.pause-btn' elements
    for (var i = 0; i < pauses.length; i++) {
        pauses[i].classList.remove('active');
    }
    // loop through all '.pause-btn' elements
    for (var i = 0; i < video_post.length; i++) {
        video_post[i].classList.remove('active');
    }
    for (var i = 0; i < videos_mp4.length; i++) {
        videos_mp4[i].pause();
    }
    vimeo.play();
    video_active.classList.add('active');
    video.classList.add('play');
    img.classList.add('play');
    play_btn.classList.add('active');
    pause_btn.classList.add('active');
}

function pauseVimeo(video_p, play, pause, player, foto) {
    var video_active = document.getElementById(video_p);
    var vimeo = new Vimeo.Player(document.getElementById(player));
    var video = document.getElementById(player);
    var img = document.getElementById(foto);
    var play_btn = document.getElementById(play);
    var pause_btn = document.getElementById(pause);

    vimeo.pause();
    video_active.classList.remove('active');
    video.classList.remove('play');
    img.classList.remove('play');
    play_btn.classList.remove('active');
    pause_btn.classList.remove('active');
}



function play_mp4(video_p, play, pause, player, foto) {
    var video_active = document.getElementById(video_p);
    var vimeo = document.getElementById(player);
    var video = document.getElementById(player);
    var img = document.getElementById(foto);
    var play_btn = document.getElementById(play);
    var pause_btn = document.getElementById(pause);
    // loop through all '.foto' elements
    for (var i = 0; i < images_v.length; i++) {
        images_v[i].classList.remove('play');
    }
    for (var i = 0; i < plays.length; i++) {
        plays[i].classList.remove('active');
    }
    for (var i = 0; i < pauses.length; i++) {
        pauses[i].classList.remove('active');
    }
    for (var i = 0; i < video_post.length; i++) {
        video_post[i].classList.remove('active');
    }
    for (var i = 0; i < videos_mp4.length; i++) {
        videos_mp4[i].pause();
    }

    for (var i = 0; i < iframes.length; i++) {
        
        //iframes[i].pause();
        var video_i = iframes[i];
        var iframeid = document.getElementById(video_i.id);
        var playervmo = new Vimeo.Player(iframeid);
        playervmo.pause();
    }






    vimeo.play();
    video_active.classList.add('active');
    video.classList.add('play');
    img.classList.add('play');
    play_btn.classList.add('active');
    pause_btn.classList.add('active');
}

function pause_mp4(video_p, play, pause, player, foto) {
    var video_active = document.getElementById(video_p);
    var vimeo = document.getElementById(player);
    var video = document.getElementById(player);
    var img = document.getElementById(foto);
    var play_btn = document.getElementById(play);
    var pause_btn = document.getElementById(pause);

    vimeo.pause();
    video_active.classList.remove('active');
    video.classList.remove('play');
    img.classList.remove('play');
    play_btn.classList.remove('active');
    pause_btn.classList.remove('active');
}