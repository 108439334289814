// https://greensock.com/st-demos/

// https://codepen.io/GreenSock/pen/OJyPmgX

gsap.registerPlugin(ScrollTrigger);



const images = gsap.utils.toArray('img');
const loader = document.querySelector('.loader--text');



const updateProgress = (instance) => 
  loader.textContent = `${Math.round(instance.progressedCount * 100 / images.length)}%`;



const showDemo = () => {
  document.body.style.overflow = 'auto';
  document.scrollingElement.scrollTo(0, 0);
  gsap.to(document.querySelector('.loader'), { autoAlpha: 0 });
  
};



// Parallax header
// https://codepen.io/GreenSock/pen/OJyPmgX

var $parallax = document.getElementsByClassName('parallax');
if($parallax.length > 0){

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".reference",
      start: "top top",
      end: "bottom top",
      scrub: true
    }
  });

  gsap.utils.toArray(".parallax").forEach(layer => {
    const depth = layer.dataset.depth;
    const movement = -(layer.offsetHeight * depth)
    tl.to(layer, {y: movement, ease: "none"}, 0)
  });

}




ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause"
});

//const w = section.querySelector('.wrapper');
//const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];

var $a2 = document.getElementsByClassName('a2');


if($a2.length > 0){
    
    gsap.from(".a2", {
      scrollTrigger: {
        trigger: ".a1",
        scrub: true,
        //pin: true, // pin the trigger element while active
        start: "top bottom",
        end: "center center"
      },
      x: "-90%", 
      transformOrigin: "top left", 
      //duration: 3
    });

}



imagesLoaded(images).on('progress', updateProgress).on('always', showDemo);







/* Lazyload img class .lazy */

ScrollTrigger.config({ limitCallbacks: true });

gsap.utils.toArray(".lazy").forEach(image => {
  
  let newSRC = image.dataset.src,
      newImage = document.createElement("img"),
 
  loadImage = () => {
    newImage.onload = () => {
      newImage.onload = null; // avoid recursion
      newImage.src = image.src; // swap the src
      image.src = newSRC;
      // place the low-res version on TOP and then fade it out.
      gsap.set(newImage, {
        position: "absolute", 
        top: image.offsetTop, 
        left: image.offsetLeft, 
        width: image.offsetWidth, 
        height: image.offsetHeight
      });
      image.parentNode.appendChild(newImage);
      gsap.to(newImage, {
        opacity: 0, 
        onComplete: () => newImage.parentNode.removeChild(newImage)
      });
      st && st.kill();
    }
    newImage.src = newSRC;
  }, 
      
  st = ScrollTrigger.create({
    trigger: image,
    start: "-50% bottom",
    onEnter: loadImage,
    onEnterBack: loadImage // make sure it works in either direction
  });
});
