//jQuery.noConflict();

jQuery(document).ready(function ($) {

    $('.slides').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        dots: true,
        margin: 0,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    




    $(".owl-prev").html('<span class="material-icons">arrow_back_ios</span>');
    $(".owl-next").html('<span class="material-icons">arrow_forward_ios</span>');


    //Go through each carousel on the page
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });


    //Go through each carousel on the page
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).removeAttr('role');
        });
    });

    // apply meta info to next and previous buttons and make them focusable
    $('.owl-carousel').find('.owl-prev').attr('role','button').attr('title','Previous');
    $('.owl-carousel').find('.owl-next').attr('role','button').attr('title','Next');
    $('.owl-carousel, .owl-prev, .owl-next').attr('tabindex','0');

    

    // Bootstrap Auto-Hiding Navbar 
    $(".header").autoHidingNavbar();

    // Highlight the top nav as scrolling occurs
    $(window).scroll(function() {
        if ($(document).scrollTop() > 100) {
            $('.header').addClass('shrink');
        } else {
            $('.header').removeClass('shrink');
        }
    });


    // smooth scrolling # from external pages
    $(window).on("load", function () {
        var urlHash = window.location.href.split("#")[1];
        if (urlHash &&  $('#' + urlHash).length) {
            $('html,body').animate({
                scrollTop: $('#' + urlHash).offset().top - 0
            }, 800);
        }
    });


    // videos play pause
/*
    document.querySelectorAll('video').forEach((video) => {
        video.addEventListener('click', function (event) {
            if (this.paused) {
            this.play(); 
            console.log('video played');
            $(this).toggleClass('active');
            }
            else {
            this.pause();
            console.log('video paused');
            $(this).toggleClass('active');
            }
        });
    });
*/


    // active vieos

/*
var video = document.getElementById("reel");
video.oncanplaythrough = function() {
    video.muted = true;
    video.play();
}
*/

    $('.video-grande video').click(function() {
        if (this.paused) {
            this.play(); 
            //console.log('video played');
            $(this).addClass('active');
            $('.video-grande .work-description').toggleClass('v-play');
            $('.video-grande .play-project').toggleClass('pause');
        }
        else {
            this.pause();
            //console.log('video paused');
            $(this).toggleClass('active');
            $('.video-grande .work-description').toggleClass('v-play');
            $('.video-grande .play-project').toggleClass('pause');
        }
    });





    // cursor pointer image

    $('.imagen-del-proyecto-home').mousemove(function(en){
        var cposn = { top: Math.abs(en.pageY) - 10, left: en.pageX + 10 };
        $('.play-project').offset(cposn);
    });
    $('.imagen-del-proyecto-home').mouseover(function(){
        $('.play-project').css('display','block');
        //$color = $(this).data('color');
        //$('#view-project').css('color',$color);
    });
    $('.imagen-del-proyecto-home').mouseout(function(){
        $('.play-project').css('display','none');
    });



    $('.cover').mousemove(function (en) {
        var cposn = { top: Math.abs(en.pageY) - 10, left: en.pageX + 10 };
        $('.play-project').offset(cposn);
    });
    $('.cover').mouseover(function () {
        $('.play-project').css('display', 'block');
    });
    $('.cover').mouseout(function () {
        $('.play-project').css('display', 'none');
    });

    $('.cover-pause').mousemove(function (en) {
        var cposn = { top: Math.abs(en.pageY) - 10, left: en.pageX + 10 };
        $('.pause-project').offset(cposn);
    });
    $('.cover-pause').mouseover(function () {
        $('.pause-project').css('display', 'block');
    });
    $('.cover-pause').mouseout(function () {
        $('.pause-project').css('display', 'none');
    });




    // Magic popup
    // 'src/js/vendors/jquery.magnific-popup.js'
    $('.popup-link').magnificPopup({
        type: 'image'
        // other options
    });


    $('.popup-modal').magnificPopup({
        type: 'inline',
        midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });


    $('.popup-gallery, .slide-fotos').magnificPopup({
        delegate: '.owl-item:not(.cloned) a',
        type: 'image',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup 
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: true,
        fixedContentPos: true,
       
    });





    // Contact
    $('.wpcf7-form .wpcf7-text').addClass('form-control');
    $('.wpcf7-textarea').addClass('form-control');
    $('.wpcf7-date').addClass('form-control');
    $('.wpcf7-submit').addClass('btn btn-primary');
    //$('.input-xlarge').addClass('form-control');


    // Modal



});
